@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;600&family=Noto+Sans:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,body
{
    width: 100%;
    /* height: 100%; */
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

